<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        Please answer each of the questions below. For some parts of the question you will need the
        data available in the Appendices of the textbook,
        <a
          href="https://ecampusontario.pressbooks.pub/genchemforgeegees/"
          rel="noopener noreferrer"
          target="_blank"
          >Gen Chem for Gee Gees</a
        >.
      </p>

      <p class="mb-3">
        a) Write the balanced equation for the reaction started below. The equilibrium arrow should
        be denoted by '&lt;=&gt;'.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="HOBr(aq) + (CH3)3N(aq) <=>" />
      </p>

      <chemical-notation-input
        v-model="inputs.balancedEqn"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) What is the pKa of the reactant acid?</p>

      <calculation-input
        v-model="inputs.pKaReact"
        class="mb-5"
        prepend-text="$\text{pK}_\text{a}\text{ of Reactant Acid:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) What is the pKb of the reactant base?</p>

      <calculation-input
        v-model="inputs.pKbReact"
        class="mb-5"
        prepend-text="$\text{pK}_\text{b}\text{ of Reactant Base:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">d) What is the pKa of the product acid?</p>

      <calculation-input
        v-model="inputs.pKaProd"
        class="mb-5"
        prepend-text="$\text{pK}_\text{a}\text{ of Product Acid:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">e) What is the pKb of the product base?</p>

      <calculation-input
        v-model="inputs.pKbProd"
        class="mb-7"
        prepend-text="$\text{pK}_\text{b}\text{ of Product Base:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">f) Will the equilibrium favour products or reactants? Justify your answer.</p>

      <v-textarea v-model="inputs.favouredSide" rows="5" outlined />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'Question469c',
  components: {
    STextarea,
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedEqn: null,
        pKaReact: null,
        pKbReact: null,
        pKaProd: null,
        pKbProd: null,
        favouredSide: null,
      },
    };
  },
};
</script>
